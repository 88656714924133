import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./AuthorDetails.css";

const AuthorDetails = () => {
  const { authorId } = useParams();
  const [author, setAuthor] = useState(null);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAuthorDetails = async () => {
      try {
        const response = await fetch(
          "https://issani.co.in/api/api.php?api=getAuthors"
        );
        const allAuthors = await response.json();
        const selectedAuthor = allAuthors.find(
          (a) => a.uid.toString() === authorId
        );
        setAuthor(selectedAuthor);

        const postsResponse = await fetch(
          `https://issani.co.in/api/api.php?api=getAuthorPosts&author_id=${authorId}`
        );
        const postsData = await postsResponse.json();
        setPosts(postsData);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching author details:", error);
      }
    };

    if (authorId) {
      fetchAuthorDetails();
    }
  }, [authorId]);

  if (loading) {
    return <div>Loading author details...</div>;
  }

  if (!author) {
    return <div>No author details found.</div>;
  }

  return (
    <div className="author-details-container">
      <Helmet>
        <title>{author.name} - Issani</title>
        <meta
          name="description"
          content={`Learn more about ${author.name} and their works.`}
        />
      </Helmet>
      {/* Author Image */}
      <div className="author-header">
        <img
          src={
            author.pic
              ? `/assets/${author.pic}` // Load image from local
              : "/assets/default-author.png" // Default image
          }
          alt={author.name}
          className="author-image"
        />
        <h1>{author.name || "Unknown Author"}</h1>
        <p>{author.about || "No description available."}</p>
      </div>

      {/* Posts by Author */}
      <div className="author-posts">
        <h2>ಇಸ್ಸನಿಯಲ್ಲಿರುವ {author.name || "Unknown Author"} ಅವರ ಬರಹಗಳು</h2>
        <div className="posts-grid">
          {posts.map((post) => (
            <div key={post.bid} className="post-card">
              <h3>
                <a href={`/post/${post.bid}`}>{post.title}</a>
              </h3>
              <p>{post.body?.substring(0, 100)}...</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AuthorDetails;
