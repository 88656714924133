import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PostDetails from "./pages/PostDetails";
import AuthorDetails from "./pages/AuthorDetails";
import Authors from "./pages/Authors";
import Layout from "./components/Layout";

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/post/:postId" element={<PostDetails />} />
          <Route path="/author/:authorId" element={<AuthorDetails />} />
          <Route path="/list-authors" element={<Authors />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
