import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import "./Home.css";
import { Helmet } from "react-helmet";

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 12;

  const fetchPosts = (page) => {
    fetch(
      `https://issani.co.in/api/api.php?api=getPosts&page=${page}&limit=${postsPerPage}`
    )
      .then((response) => response.json())
      .then((data) => {
        setPosts(data.posts);
        setTotalPages(data.pagination.total_pages);
        setCurrentPage(data.pagination.current_page);
      })
      .catch((error) => console.error("Error fetching posts:", error));
  };

  useEffect(() => {
    fetchPosts(currentPage);
  }, [currentPage]);

  return (
    <div>
      <div className="home-container">
        <Helmet>
          <title>Home - Issani</title>
          <meta
            name="description"
            content="Welcome to Issani, explore posts and authors."
          />
        </Helmet>
        <div className="posts">
          {posts.map((post) => (
            <div key={post.id} className="post-card">
              {/* Add the image */}
              {post.img ? (
                <img
                  src={
                    post.img.startsWith("http")
                      ? post.img
                      : `/assets/${post.img}`
                  } // Handles both external and local images
                  alt={post.title}
                  className="post-card-image"
                />
              ) : (
                <div className="no-image-placeholder">No Image Available</div>
              )}
              <h2>
                <Link to={`/post/${post.bid}`}>{post.title}</Link>{" "}
                {/* Dynamic Link */}
              </h2>
              <p>{post.body.substring(0, 100)}...</p>
            </div>
          ))}
        </div>
        <div className="pagination">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
