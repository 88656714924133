import React, { useEffect, useState } from "react";
import "./Authors.css";
import { Helmet } from "react-helmet";

const Authors = () => {
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const response = await fetch(
          "https://issani.co.in/api/api.php?api=getAuthors"
        );
        const data = await response.json();
        setAuthors(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
    };

    fetchAuthors();
  }, []);

  if (loading) {
    return <div>Loading authors...</div>;
  }

  return (
    <div className="authors-container">
      <Helmet>
        <title>ನಮ್ಮ ಬರಹಗಾರರು - Issani</title>
        <meta
          name="description"
          content="Welcome to Issani, explore posts and authors."
        />
      </Helmet>
      <h1>ನಮ್ಮ ಬರಹಗಾರರು</h1>
      <div className="authors-grid">
        {authors.map((author) => (
          <div key={author.uid} className="author-card">
            <img
              src={
                author.pic
                  ? `/assets/${author.pic}` // Local folder for profile pictures
                  : "/assets/default-author.png" // Local default image
              }
              alt={author.name}
              className="author-image"
            />
            <h2>{author.name}</h2>
            <a href={`/author/${author.uid}`} className="view-posts-link">
              ಇವರ ಬರಹಗಳು
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Authors;
