import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./PostDetails.css";

const PostDetails = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPostDetails = async () => {
      try {
        const response = await fetch(
          `https://issani.co.in/api/api.php?api=getPost&post_id=${postId}`
        );
        const data = await response.json();
        setPost(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };

    if (postId) {
      fetchPostDetails();
    }
  }, [postId]);

  const processContentWithHighlightsAndImage = (body, imgUrl) => {
    if (!body) return "";

    let processedContent = body;

    // Replace *img* with the actual image tag
    if (imgUrl) {
      processedContent = processedContent.replace(
        /\*img\*/g,
        `<img src="${imgUrl}" alt="Post Image" class="inline-post-image" />`
      );
    }

    // Highlight text between *q* and *cq*
    processedContent = processedContent.replace(
      /\*q\*(.*?)\*cq\*/gs,
      '<span class="highlight-text">$1</span>'
    );

    return processedContent;
  };

  if (loading) {
    return <div>Loading post details...</div>;
  }

  if (!post) {
    return <div>No post details available.</div>;
  }

  const imgUrl = post.img ? `https://issani.co.in/${post.img}` : null;

  return (
    <div className="post-details-container">
      {/* Dynamically Update Page Title */}
      <Helmet>
        <title>{post.title} - Issani</title>
        <meta
          name="description"
          content={`Read the full post: ${post.title}`}
        />
      </Helmet>

      {/* Post Content */}
      <h1>{post.title}</h1>
      <div className="author-name">
        ಲೇಖಕರು : <a href={`/author/${post.author}`}>{post.author_name}</a>
      </div>
      {/* Share Buttons */}
      <div className="share-buttons">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/assets/facebook-logo.png"
            alt="Share on Facebook"
            className="share-button-logo"
          />
        </a>
        <a
          href={`https://www.instagram.com/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/assets/instagram-logo.png"
            alt="Share on Instagram"
            className="share-button-logo"
          />
        </a>
        <a
          href={`https://api.whatsapp.com/send?text=${window.location.href}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/assets/whatsapp-logo.png"
            alt="Share on WhatsApp"
            className="share-button-logo"
          />
        </a>
      </div>

      {imgUrl && !post.body.includes("*img*") && (
        <img
          src={imgUrl}
          alt="top image"
          className="post-top-image inline-post-image"
        />
      )}
      {/* Main Content */}
      <div
        className="post-content"
        dangerouslySetInnerHTML={{
          __html: processContentWithHighlightsAndImage(
            post.body.replace(/\n/g, "<br />"),
            imgUrl
          ),
        }}
      />
    </div>
  );
};

export default PostDetails;
